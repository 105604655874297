import { AdminAuthBody } from '../models/AuthBody';
import storageService, { STORAGE_KEYS } from './storageService';

export default {
  get authBody() {
    return storageService.getItem<AdminAuthBody>(STORAGE_KEYS.AUTH);
  },

  get admin() {
    return storageService.getItem<AdminAuthBody>(STORAGE_KEYS.AUTH)?.admin;
  },

  get token(): string {
    return storageService.getItem<AdminAuthBody>(STORAGE_KEYS.AUTH)?.token;
  },

  saveAuthBody(authBody: AdminAuthBody) {
    storageService.setItem(STORAGE_KEYS.AUTH, authBody);
  },

  removeAuthBody() {
    storageService.removeItem(STORAGE_KEYS.AUTH);
  },
};
