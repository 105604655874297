import React, { useCallback, useState, useEffect } from 'react';
import {
  Table,
  Pagination,
  Loader,
  Button,
  Input,
  Segment,
  Header,
} from 'semantic-ui-react';
import './UnverifiedUsersTable.styles.scss';
import usePagination from 'hooks/usePagination';
import api from 'api';
import ConfirmPopup from 'components/ConfirmPopup';
import { toast } from 'react-toastify';
import { UnverifiedUser } from 'models/UnverifiedUser';
import DatePreview from 'components/DatePreview';
import UnverifiedUserModal from 'router/subrouters/Home/pages/UnverifiedUsersTable/components/UnverifiedUserModal';

type UnverifiedUserTableProps = {};

const UnverifiedUsersTable: React.FC<UnverifiedUserTableProps> = (props) => {
  const [searchInput, setSearchInput] = useState('');
  const { items, loadItems, setItems, currentPage, totalPages } = usePagination<
    UnverifiedUser
  >(api.users.unverifiedUsers.get, { clearOnLoad: false });

  useEffect(() => {
    loadItems(1, { $searchString: searchInput });
  }, [searchInput, loadItems]);

  const onCreate = useCallback(
    async (tmpUserId: string) => {
      try {
        await api.users.create(tmpUserId);

        setItems((items) => items.filter((item) => item._id !== tmpUserId));

        toast.success('Successfully approved user.');
      } catch (err) {
        const error = err as any;
        toast.error(error?.response.data.message || error.message);
      }
    },
    [setItems],
  );

  const onDelete = useCallback(
    async (tmpUserId: string) => {
      try {
        await api.users.unverifiedUsers.deleteOne(tmpUserId);

        setItems((items) => items.filter((item) => item._id !== tmpUserId));

        toast.success('Successfully deleted unverified user.');
      } catch (err) {
        const error = err as any;
        toast.error(error?.response.data.message || error.message);
      }
    },
    [setItems],
  );

  if (!items) {
    return <Loader active />;
  }

  return (
    <Segment>
      <Header>Unverified users</Header>
      <Input
        focus
        placeholder="Search..."
        value={searchInput}
        onChange={(e, field) => setSearchInput(field.value)}
      />
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Verification status</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((unverifiedUser) => (
            <Table.Row key={unverifiedUser._id}>
              <Table.Cell>
                {unverifiedUser.firstName} {unverifiedUser.lastName}
              </Table.Cell>
              <Table.Cell>{unverifiedUser.email}</Table.Cell>
              <Table.Cell>{unverifiedUser.userType}</Table.Cell>
              <Table.Cell>
                <a
                  href={`https://station.veriff.com/verifications/${unverifiedUser.verificationSessionInfo?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {unverifiedUser.verificationStatus}
                </a>
              </Table.Cell>
              <Table.Cell>
                <DatePreview>{unverifiedUser.createdAt}</DatePreview>
              </Table.Cell>
              <Table.Cell
                onClick={(ev: any) => {
                  ev.stopPropagation();
                }}
                selectable={false}
              >
                <UnverifiedUserModal unverifiedUser={unverifiedUser}>
                  <Button icon="address book" color="teal"></Button>
                </UnverifiedUserModal>
                <ConfirmPopup
                  confirm={() => onCreate(unverifiedUser._id)}
                  text="Are you sure you want to verify this user?"
                  buttonText="Verify"
                >
                  <Button icon="check" color="green"></Button>
                </ConfirmPopup>
                <ConfirmPopup
                  confirm={() => onDelete(unverifiedUser._id)}
                  text="Are you sure you want to remove this unverified user?"
                  buttonText="Delete"
                >
                  <Button icon="trash" color="red"></Button>
                </ConfirmPopup>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="6">
              <Pagination
                floated="right"
                activePage={currentPage}
                onPageChange={(ev, { activePage }) => loadItems(activePage)}
                totalPages={totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

export default UnverifiedUsersTable;
