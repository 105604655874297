import React, { useState, useCallback } from 'react';
import { Modal, Table, Button } from 'semantic-ui-react';
import './UserModal.styles.scss';

import { User } from 'models/User';
import DatePreview from 'components/DatePreview';
import { AddressType } from 'models/UserNestedTypes';

type UserPreviewModalProps = {
  user: User;
};

const UserModal: React.FC<UserPreviewModalProps> = ({ children, user }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(
    (ev) => {
      ev.stopPropagation();
      setIsOpen(false);
    },
    [setIsOpen],
  );
  const onOpen = useCallback(
    (ev) => {
      ev.stopPropagation();
      setIsOpen(true);
    },
    [setIsOpen],
  );

  const getAddress = useCallback(
    (address: AddressType) =>
      `${address.streetAddress} ${
        address.building ? `(${address.building}) ` : ''
      }${address.city} ${address.country} ${address.zipCode}`,
    [],
  );

  return (
    <Modal
      open={isOpen}
      trigger={React.cloneElement(children as any, { onClick: onOpen })}
      onClose={onClose}
    >
      <Modal.Header>
        {user.firstName} {user.lastName}
      </Modal.Header>

      <Modal.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Property</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>First name</Table.Cell>
              <Table.Cell>{user.firstName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last name</Table.Cell>
              <Table.Cell>{user.lastName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Email</Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>User type</Table.Cell>
              <Table.Cell>{user.userType}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Verification Url</Table.Cell>
              <Table.Cell>
                <a
                  href={`https://station.veriff.com/verifications/${user.verificationSessionInfo?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Company</Table.Cell>
              <Table.Cell>{user.company}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Address</Table.Cell>
              <Table.Cell>{getAddress(user.address)}</Table.Cell>
            </Table.Row>
            {user.userType === 'Broker' && (
              <React.Fragment>
                <Table.Row>
                  <Table.Cell>Proof of address</Table.Cell>
                  <Table.Cell>
                    <a
                      href={user.proofOfAddress.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {user.proofOfAddressType}
                    </a>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Work Title</Table.Cell>
                  <Table.Cell>{user.workTitle}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Legal Form</Table.Cell>
                  <Table.Cell>{user.legalForm}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            )}
            {user.legalPersonInfo && user.proofOfPresentation && (
              <React.Fragment>
                <Table.Row>
                  <Table.HeaderCell>Legal person information</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Name</Table.Cell>
                  <Table.Cell>
                    {user.legalPersonInfo.firstName}{' '}
                    {user.legalPersonInfo.lastName}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Legal Form</Table.Cell>
                  <Table.Cell>{user.legalPersonInfo.company}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Address</Table.Cell>
                  <Table.Cell>
                    <Table.Cell>
                      {getAddress(user.legalPersonInfo.address)}
                    </Table.Cell>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Proof of presentation</Table.Cell>
                  <Table.Cell>
                    <a
                      href={user.proofOfPresentation.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            )}
            <Table.Row>
              <Table.Cell>Created at</Table.Cell>
              <Table.Cell>
                <DatePreview>{user.createdAt}</DatePreview>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UserModal;
