import React, { useState, useCallback } from 'react';
import { Form, Button, Modal } from 'semantic-ui-react';
import './AdminModal.styles.scss';
import { capitalize } from 'utils/string';
import { Admin } from 'models/Admin';

type AdminModalProps = {
  onSubmit: (updated: Partial<Admin>, admin?: Admin) => any;
  action: 'create' | 'update';
  admin?: Admin;
};

const AdminModal: React.FC<AdminModalProps> = ({
  onSubmit = () => {},
  children,
  admin,
  action,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(admin?.name || '');
  const [email, setEmail] = useState(admin?.email || '');
  const [password, setPassword] = useState('');

  const handleSubmit = useCallback(
    (ev) => {
      const body = {
        name,
        email,
      } as Partial<Admin>;
      if (action === 'create') {
        body.password = password;
      }
      onSubmit(body, admin);
      setIsOpen(false);
    },
    [name, email, action, onSubmit, admin, password],
  );

  const onClose = useCallback(
    (ev) => {
      ev.stopPropagation();
      setIsOpen(false);
    },
    [setIsOpen],
  );
  const onOpen = useCallback(
    (ev) => {
      ev.stopPropagation();
      setIsOpen(true);
    },
    [setIsOpen],
  );

  return (
    <Modal
      open={isOpen}
      trigger={React.cloneElement(children as any, { onClick: onOpen })}
      onClose={onClose}
    >
      <Modal.Header>{capitalize(action)} Admin</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Input
            fluid
            label="Name"
            required
            value={name}
            onChange={(e, { value }) => setName(value)}
          />
          <Form.Input
            fluid
            label="Email"
            required
            value={email}
            type="email"
            onChange={(e, { value }) => setEmail(value)}
          />
          {action === 'create' && (
            <Form.Input
              fluid
              label="Password"
              required
              value={password}
              type="password"
              onChange={(e, { value }) => setPassword(value)}
            />
          )}
          <Button type="submit">{capitalize(action)}</Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AdminModal;
