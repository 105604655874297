import { combineReducers } from 'redux';
import currentAdminReducer from './auth';
import { Admin } from '../../models/Admin';

export default combineReducers({
  currentAdmin: currentAdminReducer,
});

export type State = {
  currentAdmin: Admin | null;
};
