import React, { useCallback, useState, useEffect } from 'react';
import {
  Table,
  Pagination,
  Loader,
  Button,
  Input,
  Segment,
  Header,
} from 'semantic-ui-react';
import './UsersTable.styles.scss';
import usePagination from 'hooks/usePagination';
import api from 'api';
import ConfirmPopup from 'components/ConfirmPopup';
import { toast } from 'react-toastify';
import { User } from 'models/User';
import DatePreview from 'components/DatePreview';
import UserModal from 'router/subrouters/Home/pages/UsersTable/components/UserModal';

type UserTableProps = {};

const UsersTable: React.FC<UserTableProps> = (props) => {
  const [searchInput, setSearchInput] = useState('');
  const { items, loadItems, setItems, currentPage, totalPages } = usePagination<
    User
  >(api.users.get, { clearOnLoad: false });

  useEffect(() => {
    loadItems(1, { $searchString: searchInput });
  }, [searchInput, loadItems]);

  const onDelete = useCallback(
    async (userId: string) => {
      try {
        await api.users.deleteOne(userId);

        setItems((items) => items.filter((item) => item._id !== userId));

        toast.success('Successfully deleted user.');
      } catch (err) {
        const error = err as any;
        toast.error(error?.response.data.message || error.message);
      }
    },
    [setItems],
  );

  if (!items) {
    return <Loader active />;
  }

  return (
    <Segment>
      <Header>Users</Header>
      <Input
        focus
        placeholder="Search..."
        value={searchInput}
        onChange={(e, field) => setSearchInput(field.value)}
      />
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((user) => (
            <Table.Row key={user._id}>
              <Table.Cell>
                {user.firstName} {user.lastName}
              </Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>{user.userType}</Table.Cell>
              <Table.Cell>
                <DatePreview>{user.createdAt}</DatePreview>
              </Table.Cell>
              <Table.Cell
                onClick={(ev: any) => {
                  ev.stopPropagation();
                }}
                selectable={false}
              >
                <UserModal user={user}>
                  <Button icon="address book" color="teal"></Button>
                </UserModal>
                <ConfirmPopup
                  confirm={() => onDelete(user._id)}
                  text="Are you sure you want to remove this user?"
                  buttonText="Delete"
                >
                  <Button icon="trash" color="red"></Button>
                </ConfirmPopup>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="6">
              <Pagination
                floated="right"
                activePage={currentPage}
                onPageChange={(ev, { activePage }) => loadItems(activePage)}
                totalPages={totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

export default UsersTable;
