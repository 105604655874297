import { useCallback, useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { PaginationResponse } from '../models/PaginationResponse';

const PAGINATION_DEFAULT_LIMIT = 20;

type GetRequest<T> = (
  skip: number,
  take: number,
  additionalParams?: Record<string, any>,
) => Promise<AxiosResponse<PaginationResponse<T>>>;

type Options = {
  take?: number;
  clearOnLoad?: boolean;
  initialLoad?: boolean;
};

export default function usePagination<T>(
  getRequest: GetRequest<T>,
  options: Options = {},
) {
  const {
    take = PAGINATION_DEFAULT_LIMIT,
    clearOnLoad = true,
    initialLoad = false,
  } = options;

  const [items, setItems] = useState<T[] | null>(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);

  const loadItems = useCallback(
    async (newPage, additionalData = {}) => {
      const page = newPage;
      setCurrentPage(page);
      if (clearOnLoad) {
        setItems(null);
      }
      try {
        const {
          data: { items, itemsTotal, totalPages },
        } = await getRequest((page - 1) * take, take, additionalData);
        setItems(items);
        setTotalPages(totalPages);
        setTotalItems(itemsTotal);
      } catch (err) {
        console.log(err);
      }
    },
    [setCurrentPage, setTotalPages, setItems, getRequest, take, clearOnLoad],
  );

  useEffect(() => {
    if (initialLoad) {
      loadItems(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadItems]);

  return { items, totalItems, totalPages, currentPage, loadItems, setItems };
}
