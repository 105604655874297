import httpClient from '../httpClient';
import { AdminAuthBody } from '../../models/AuthBody';

async function login(AdminData: { email: string; password: string }) {
  return httpClient.post<AdminAuthBody>('auth/admins/login', AdminData);
}

async function forgotPassword(email: string) {
  return httpClient.post('auth/admins/forgot-password', {
    email,
  });
}

async function resetPassword(token: string, password: string) {
  return httpClient.post(`auth/admins/${token}/reset-password`, {
    password,
  });
}

async function changePassword(oldPassword: string, newPassword: string) {
  return httpClient.post('auth/admins/change-password', {
    oldPassword,
    newPassword,
  });
}

export default {
  login,
  forgotPassword,
  resetPassword,
  changePassword,
};
