import React, { useState, useCallback } from 'react';
import { Modal, Table, Button } from 'semantic-ui-react';
import './UnverifiedUserModal.styles.scss';
import { UnverifiedUser } from 'models/UnverifiedUser';
import DatePreview from 'components/DatePreview';
import { AddressType } from 'models/UserNestedTypes';

type UnverifiedUserPreviewModalProps = {
  unverifiedUser: UnverifiedUser;
};

const UnverifiedUserModal: React.FC<UnverifiedUserPreviewModalProps> = ({
  children,
  unverifiedUser,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(
    (ev) => {
      ev.stopPropagation();
      setIsOpen(false);
    },
    [setIsOpen],
  );
  const onOpen = useCallback(
    (ev) => {
      ev.stopPropagation();
      setIsOpen(true);
    },
    [setIsOpen],
  );

  const getAddress = useCallback(
    (address: AddressType) =>
      `${address.streetAddress} ${
        address.building ? `(${address.building}) ` : ''
      }${address.city} ${address.country} ${address.zipCode}`,
    [],
  );

  return (
    <Modal
      open={isOpen}
      trigger={React.cloneElement(children as any, { onClick: onOpen })}
      onClose={onClose}
    >
      <Modal.Header>
        {unverifiedUser.firstName} {unverifiedUser.lastName}
      </Modal.Header>

      <Modal.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Property</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>First name</Table.Cell>
              <Table.Cell>{unverifiedUser.firstName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last name</Table.Cell>
              <Table.Cell>{unverifiedUser.lastName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Email</Table.Cell>
              <Table.Cell>{unverifiedUser.email}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>User type</Table.Cell>
              <Table.Cell>{unverifiedUser.userType}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Verification Url</Table.Cell>
              <Table.Cell>
                <a
                  href={`https://station.veriff.com/verifications/${unverifiedUser.verificationSessionInfo?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {unverifiedUser.verificationStatus}
                </a>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Company</Table.Cell>
              <Table.Cell>{unverifiedUser.company}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Address</Table.Cell>
              <Table.Cell>{getAddress(unverifiedUser.address)}</Table.Cell>
            </Table.Row>
            {unverifiedUser.userType === 'Broker' && (
              <React.Fragment>
                <Table.Row>
                  <Table.Cell>Proof of address</Table.Cell>
                  <Table.Cell>
                    <a
                      href={unverifiedUser.proofOfAddress.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {unverifiedUser.proofOfAddressType}
                    </a>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Work Title</Table.Cell>
                  <Table.Cell>{unverifiedUser.workTitle}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Legal Form</Table.Cell>
                  <Table.Cell>{unverifiedUser.legalForm}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            )}
            {unverifiedUser.legalPersonInfo &&
              unverifiedUser.proofOfPresentation && (
                <React.Fragment>
                  <Table.Row>
                    <Table.HeaderCell>
                      Legal person information
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Name</Table.Cell>
                    <Table.Cell>
                      {unverifiedUser.legalPersonInfo.firstName}{' '}
                      {unverifiedUser.legalPersonInfo.lastName}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Legal Form</Table.Cell>
                    <Table.Cell>
                      {unverifiedUser.legalPersonInfo.company}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Address</Table.Cell>
                    <Table.Cell>
                      <Table.Cell>
                        {getAddress(unverifiedUser.legalPersonInfo.address)}
                      </Table.Cell>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Proof of presentation</Table.Cell>
                    <Table.Cell>
                      <a
                        href={unverifiedUser.proofOfPresentation.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link
                      </a>
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              )}
            <Table.Row>
              <Table.Cell>Created at</Table.Cell>
              <Table.Cell>
                <DatePreview>{unverifiedUser.createdAt}</DatePreview>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UnverifiedUserModal;
