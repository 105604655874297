import React, { useCallback } from 'react';
import { RouteComponentProps, Link, useHistory } from 'react-router-dom';
import { Container, Menu, Sidebar, Icon, Segment } from 'semantic-ui-react';
import { capitalize } from 'utils/string';
import Routes from 'router/components/Routes';
import routes from 'router/subrouters/Home/routes';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store/reducers';
import credentialsService from 'services/credentialsService';
import { FETCH_CURRENT_USER } from 'store/actions/actionTypes';
import { Admin } from 'models/Admin';

const HomeRouter: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const admin = useSelector<State, Admin>((state) => state.currentAdmin);

  const logOut = useCallback(() => {
    credentialsService.removeAuthBody();
    dispatch({ type: FETCH_CURRENT_USER, payload: null });
    history.push('/login');
  }, [dispatch, history]);

  return (
    <Sidebar.Pushable as={Segment}>
      <Sidebar as={Menu} icon="labeled" inverted vertical visible width="thin">
        <Menu.Item as={Link} to="/home/admins" header>
          <Icon name="user secret" />
          Admins
        </Menu.Item>
        <Menu.Item as={Link} to="/home/unverified-users" header>
          <Icon name="user times" />
          Unverified users
        </Menu.Item>
        <Menu.Item as={Link} to="/home/users" header>
          <Icon name="users" />
          Users
        </Menu.Item>
      </Sidebar>

      <Sidebar.Pusher className="home-page-content">
        <Segment basic>
          <Menu inverted size="massive">
            <Menu.Item>{capitalize(admin.name)}</Menu.Item>
            <Menu.Item onClick={logOut}>Log out</Menu.Item>
          </Menu>
          <Container className="routes-container">
            <Routes routes={routes} />
          </Container>
        </Segment>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default HomeRouter;
