import React, { useState } from 'react';
import './ForgotPassword.styles.scss';
import { Header, Form, Button, Segment } from 'semantic-ui-react';
import api from 'api';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  const onHandleSubmit = async () => {
    try {
      setIsSubmitted(true);
      await api.auth.forgotPassword(email);
    } catch (error) {
      toast.error('Invalid email.');
      setIsSubmitted(false);
    }
  };

  return (
    <div className="container">
      <Segment padded="very" raised className="section-container">
        <Link to="/login">Back to login</Link>
        <Header as="h1" textAlign="center">
          Forgot Password
        </Header>
        {isSubmitted ? (
          <p>
            Further instructions are sent to your email: <b>{email}</b>
          </p>
        ) : (
          <Form
            size="large"
            className="form-container"
            onSubmit={onHandleSubmit}
          >
            <Form.Input
              label="Email"
              name="email"
              placeholder="name@example.com"
              fluid
              icon="user"
              iconPosition="left"
              autoFocus
              required
              value={email}
              onChange={(_, { value }) => setEmail(value)}
            />
            <Button color="blue" fluid size="large">
              Send Email
            </Button>
          </Form>
        )}
      </Segment>
    </div>
  );
};

export default ForgotPassword;
