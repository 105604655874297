import React, { useState, useEffect } from 'react';
import './ResetPassword.styles.scss';
import { Header, Form, Button, Segment } from 'semantic-ui-react';
import api from 'api';
import { toast } from 'react-toastify';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import credentialsService from 'services/credentialsService';
import useQueryParams from 'hooks/useQueryParams';
import { FETCH_CURRENT_USER } from 'store/actions/actionTypes';

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    params: { token },
  } = useQueryParams<{ token: string }>();

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const onHandleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      const { data } = await api.auth.resetPassword(token, password);
      credentialsService.saveAuthBody(data);
      dispatch({ type: FETCH_CURRENT_USER, payload: data.admin });
      toast.success('Password successfully changed.');
      history.push('/home/admins');
    } catch (error) {
      toast.error('Error restarting password.');
    }
  };

  useEffect(() => {
    if (!token) {
      toast.error('Invalid password reset code.');
      history.push('/login');
    }
  }, [token, history]);

  return (
    <div className="container">
      <Segment padded="very" raised className="section-container">
        <Link to="/login">Back to login</Link>
        <Header as="h1" textAlign="center">
          Reset Password
        </Header>
        <Form size="large" className="form-container" onSubmit={onHandleSubmit}>
          <Form.Input
            label="New password"
            name="password"
            type="password"
            fluid
            icon="lock"
            iconPosition="left"
            autoFocus
            required
            value={password}
            onChange={(_, { value }) => setPassword(value)}
          />
          <Form.Input
            label="Confirm password"
            name="password"
            type="password"
            fluid
            icon="lock"
            iconPosition="left"
            required
            value={passwordConfirm}
            onChange={(_, { value }) => setPasswordConfirm(value)}
          />
          <Button
            color="blue"
            fluid
            size="large"
            disabled={
              !password || !passwordConfirm || passwordConfirm !== password
            }
          >
            Reset and login
          </Button>
        </Form>
      </Segment>
    </div>
  );
};

export default ResetPassword;
