import credentialsService from 'services/credentialsService';
import { FETCH_CURRENT_USER } from '../actions/actionTypes';
import { Admin } from '../../models/Admin';

const currentAdminReducer = (
  state: Admin = credentialsService.admin || null,
  action: any,
) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CURRENT_USER:
      return payload;
    default:
      return state;
  }
};

export default currentAdminReducer;
