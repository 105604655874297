import React from 'react';
import './Login.styles.scss';
import { Header, Form, Button, Segment } from 'semantic-ui-react';
import formDataToObject from 'utils/formData';
import api from 'api';
import { useDispatch } from 'react-redux';
import { FETCH_CURRENT_USER } from 'store/actions/actionTypes';
import credentialsService from 'services/credentialsService';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const Login: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onHandleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const loginObject = formDataToObject(
      new FormData(event.target as HTMLFormElement),
    );
    try {
      const { data } = await api.auth.login(loginObject);
      credentialsService.saveAuthBody(data);
      dispatch({ type: FETCH_CURRENT_USER, payload: data.admin });
      history.push('/home/admins');
    } catch (error) {
      toast.error('Invalid email or password.');
    }
  };

  return (
    <div className="container">
      <Segment padded="very" raised className="section-container">
        <Header as="h1" textAlign="center">
          Login to your account
        </Header>
        <Form size="large" className="form-container" onSubmit={onHandleSubmit}>
          <Form.Input
            label="Email"
            name="email"
            placeholder="name@example.com"
            fluid
            icon="user"
            iconPosition="left"
            autoFocus
            required
          />
          <Form.Input
            label="Password"
            name="password"
            type="password"
            placeholder="Password"
            fluid
            icon="lock"
            iconPosition="left"
            required
          />
          <Button color="blue" fluid size="large">
            Login
          </Button>
          <Link className="forgot-password" to="/forgot-password">
            Forgot password
          </Link>
        </Form>
      </Segment>
    </div>
  );
};

export default Login;
